import React, { useState, useEffect } from "react";
import { useAuthenticator } from "@aws-amplify/ui-react";
import { API, graphqlOperation } from "aws-amplify";
import { Scheduler } from "@aldabil/react-scheduler";
import { createEventPublic } from "../graphql/mutations";
import { v4 as uuidv4 } from "uuid";
import { GRAPHQL_AUTH_MODE } from "@aws-amplify/auth";
import {
  resources,
  resourceFields,
  userDay,
  userFields,
  translations,
  view,
  recourseHeaderComponent,
} from "../common/calendar-settings";
import { getHashVariable, getCookie, setCookie } from "../common/functions";
import uk from "date-fns/locale/uk";
import { Button, PhoneNumberField } from "@aws-amplify/ui-react";
import { TextField } from "@aws-amplify/ui-react";
import { DialogActions, DialogTitle, Dialog, DialogContentText, DialogContent } from "@mui/material";
import { EventStatus, CourtPrices } from "../common/constants";

import "./UserView.css";

const parseEventDetails = ({ courtId, start, end }) => {
  let courtTitle = "";
  let selectedCourt = resources.find((res) => res.court_id === courtId);
  if (selectedCourt) {
    courtTitle = selectedCourt.title;
  }

  const monthDay = start.toLocaleDateString("uk-UA", {
    month: "numeric",
    day: "numeric",
  });

  const fromTime = start.toLocaleTimeString("uk-UA", {
    hour: "2-digit",
    minute: "2-digit",
  });

  const toTime = end.toLocaleTimeString("uk-UA", {
    hour: "2-digit",
    minute: "2-digit",
  });

  return { courtTitle, monthDay, fromTime, toTime };
};

function UserView() {
  const { user } = useAuthenticator((context) => [context.user]);

  const CustomEditor = ({ scheduler }) => {
    const event = scheduler.edited;

    // console.log(scheduler);

    // Make your own form/state
    const [state, setState] = useState({
      name: event?.name || user?.attributes?.name || getCookie("name"),
      phone: event?.phone || user?.attributes?.phone_number?.replace(/^\+380/, "") || getCookie("phone"),
    });
    const [error, setError] = useState({ name: "", phone: "" });
    const [formHtml, setFormHtml] = useState("");

    const { courtTitle, monthDay, fromTime, toTime } = parseEventDetails({
      courtId: scheduler.court_id,
      start: scheduler.state.start.value,
      end: scheduler.state.end.value,
    });

    const handleChange = (value, name) => {
      setState((prev) => {
        return {
          ...prev,
          [name]: value,
        };
      });
    };

    async function addEventPublic(event) {
      try {
        event["event_id"] = event["event_id"] || uuidv4();
        await API.graphql({
          ...graphqlOperation(createEventPublic, { input: event }),
          authMode: GRAPHQL_AUTH_MODE.AWS_IAM,
        });
      } catch (err) {
        console.log("error creating event:", err);
      }
      return new Promise((res) => {
        res({ ...event });
      });
    }

    async function sendNotice(message) {
      const apiName = "liqpay";
      const path = "/sendNotice";
      const myInit = {
        body: { message },
      };

      try {
        const response = await API.post(apiName, path, myInit);
        return response.html;
      } catch (e) {
        return "";
      }
    }

    const handleSubmit = async () => {
      setError({});
      if (!state.name || !state.phone) {
        return setError({
          name: "Введіть імʼя та номер!",
        });
      }
      // Your own validation
      if (state.name.length < 3) {
        return setError({
          name: "Введіть коректне імʼя!",
        });
      }
      if (!state.phone.match(/\d/g) || state.phone.match(/\d/g).length !== 9) {
        return setError({
          phone: "Неправильний номер",
        });
      }

      // scheduler.loading(true);

      // const validateUrl = (await API.endpoint("liqpay")) + "/validate";

      setCookie("name", state.name);
      setCookie("phone", state.phone);

      const result = await addEventPublic({
        start: scheduler.state.start.value,
        end: scheduler.state.end.value,
        court_id: scheduler.court_id,
        title: `${state.name} +380${state.phone}`,
      });

      // const html = await getPaymentForm({
      //   amount: CourtPrices.RENT,
      //   description: `Оренда корту ${courtTitle} ${monthDay} ${fromTime} - ${toTime}`,
      //   order_id: result.event_id,
      //   result_url: validateUrl,
      //   server_url: validateUrl,
      // });
      // setFormHtml(html);
      // setTimeout(function () {
      //   document.querySelector("form[action*='liqpay']")?.submit();
      // }, 300);
      scheduler.close();
      setEventSuccessData((prevData) => ({
        ...prevData,
        id: result.event_id,
        courtTitle,
        orderTitle: `${state.name} +380${state.phone}`,
        date: monthDay,
        time: `${fromTime}-${toTime}`,
      }));
      await sendNotice(
        `Нова заявка на оренду корту \r\n${courtTitle} \r\n${monthDay} \r\n${fromTime} - ${toTime} \r\n${state.name} +380${state.phone}`
      );
    };

    return (
      <div style={{ padding: "0 0 1rem 0" }}>
        <div style={{ padding: "1rem" }}>
          <div style={{ marginBottom: "20px" }}>
            Оренда корту <b>{courtTitle}</b> <br />
            {`Дата: ${scheduler.state.start.value.toLocaleDateString("uk-UA", {
              month: "numeric",
              day: "numeric",
            })}`}{" "}
            <br />
            {`Час: ${scheduler.state.start.value.toLocaleTimeString("uk-UA", {
              hour: "2-digit",
              minute: "2-digit",
            })}-${scheduler.state.end.value.toLocaleTimeString("uk-UA", {
              hour: "2-digit",
              minute: "2-digit",
            })}`}
            <br />
            Сума: <b>{CourtPrices.RENT} грн</b>
          </div>
          <div style={{ marginBottom: "10px" }}>
            <TextField
              label="Імʼя"
              value={state.name}
              onChange={(e) => handleChange(e.target.value, "name")}
              errorMessage={error.name}
              hasError={Boolean(error.name)}
              variant="standard"
              required
            />
          </div>
          <div style={{ marginBottom: "10px" }}>
            <PhoneNumberField
              label="Номер телефону"
              value={state.phone}
              onChange={(e) => handleChange(e.target.value, "phone")}
              variant="standard"
              dialCodeList={["+380"]}
              hasError={Boolean(error.phone)}
              errorMessage={error.phone}
              required
            />
          </div>
          <div
            style={{ display: "none" }}
            dangerouslySetInnerHTML={{
              __html: formHtml,
            }}
          />
        </div>
        <DialogActions>
          <Button variation="primary" colorTheme="error" onClick={scheduler.close}>
            Відміна
          </Button>
          <Button variation="primary" colorTheme="success" onClick={handleSubmit}>
            Забронювати
          </Button>
        </DialogActions>
      </div>
    );
  };

  async function getPaymentForm({ amount, description, order_id, result_url }) {
    const apiName = "liqpay";
    const path = "/generate";
    const myInit = {
      queryStringParameters: { type: "generate", amount, description, order_id, result_url },
    };

    try {
      const response = await API.get(apiName, path, myInit);
      return response.html;
    } catch (e) {
      return "";
    }
  }

  async function getEvents(filter = {}, defaultFilter = { status: { eq: EventStatus.APPROVED } }) {
    filter = { ...filter, ...defaultFilter };
    const eventData = await API.graphql({
      ...graphqlOperation(
        `query ListEvents(
    $event_id: ID
    $filter: ModelEventFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listEvents(
      event_id: $event_id
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        event_id
        court_id
        start
        end
        __typename
      }
      nextToken
      __typename
    }
  }
`,
        { filter }
      ),
      authMode: GRAPHQL_AUTH_MODE.AWS_IAM,
    });
    return eventData.data.listEvents.items.map((item) => ({
      ...item,
      title: "бронь",
      start: new Date(Date.parse(item.start)),
      end: new Date(Date.parse(item.end)),
      disabled: true,
      color: "#0d387d",
      editable: false,
      deletable: false,
      draggable: false,
    }));
  }

  async function fetchEvents(remoteQuery) {
    try {
      const events = await getEvents({
        start: { gt: remoteQuery.start.toISOString() },
        end: { lt: remoteQuery.end.toISOString() },
      });
      return new Promise((res) => {
        res(events);
      });
    } catch (err) {
      console.log("error fetching events:", err);
    }
  }

  const dayCellRenderer = (props) => {
    const isDisabled = new Date(props.start) <= new Date();
    return (
      <Button
        style={{
          cursor: isDisabled ? "not-allowed" : "pointer",
          pointerEvents: isDisabled ? "none" : "all",
          background: isDisabled ? "rgb(4 63 137)" : "rgb(225 242 47)",
          color: isDisabled ? "#ccc" : "#0167d3",
        }}
        border={"none"}
        disabled={isDisabled}
        onClick={props.onClick}
      >
        {isDisabled ? "" : "вільно"}
      </Button>
    );
  };

  let selectedDate = new Date();
  if (selectedDate.getHours() > userDay.endHour - 2) {
    selectedDate.setDate(selectedDate.getDate() + 1);
  }

  const eventRenderer = ({ event }) => {
    return (
      <div
        style={{
          color: "#9beefb",
          background: "rgb(1 103 211)",
          width: "100%",
          height: "100%",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          fontWeight: 700,
          cursor: "not-allowed",
        }}
      >
        {event.title}
      </div>
    );
  };
  const eventId = getHashVariable("event_success");
  const [eventSuccessData, setEventSuccessData] = useState({
    id: null,
  });

  useEffect(() => {
    if (eventId && !eventSuccessData.courtTitle) {
      const fetchData = async () => {
        try {
          const matchedEvents = await getEvents({
            event_id: { eq: eventId },
          });
          const matchedEvent = matchedEvents.pop();
          if (!matchedEvent) {
            return handleSuccessEventClose();
          }
          const { courtTitle, monthDay, fromTime, toTime } = parseEventDetails({
            courtId: matchedEvent.court_id,
            start: matchedEvent.start,
            end: matchedEvent.end,
          });

          const orderTitle = getHashVariable("title");

          setEventSuccessData((prevData) => ({
            ...prevData,
            id: eventId,
            courtTitle,
            orderTitle,
            date: monthDay,
            time: `${fromTime}-${toTime}`,
          }));
        } catch (error) {
          console.error("Error fetching data:", error);
        }
      };

      fetchData();
    }
  }, [eventId, eventSuccessData]);

  const handleSuccessEventClose = () => {
    setEventSuccessData({ id: null });
    window.location.hash = "";
  };

  return (
    <div className="App-scheduler-userView">
      <Dialog onClose={handleSuccessEventClose} open={Boolean(eventSuccessData.id)}>
        <DialogTitle sx={{ fontSize: 18 }}>
          Дякуємо, наш менеджер звʼяжеться з вами для підтвердження замовлення!
        </DialogTitle>
        <DialogContent>
          {eventSuccessData?.courtTitle ? (
            <>
              <DialogContentText>
                <b>Деталі:</b>
              </DialogContentText>
              <DialogContentText>
                Оренда корту <b>{eventSuccessData.courtTitle}</b> <br />
                {`Дата: ${eventSuccessData.date}`} <br />
                {`Час: ${eventSuccessData.time}`} <br />
                {eventSuccessData.orderTitle} <br />
              </DialogContentText>
            </>
          ) : (
            ""
          )}
        </DialogContent>
        <DialogActions>
          <Button variation="primary" colorTheme="success" onClick={handleSuccessEventClose} autoFocus>
            ОК
          </Button>
        </DialogActions>
      </Dialog>

      <Scheduler
        customEditor={(scheduler) => <CustomEditor scheduler={scheduler} />}
        view={view}
        resources={resources}
        resourceFields={resourceFields}
        month={null}
        week={null}
        day={{ ...userDay, cellRenderer: dayCellRenderer }}
        selectedDate={selectedDate}
        fields={userFields}
        translations={translations}
        hourFormat="24"
        timeZone="Europe/Kyiv"
        locale={uk}
        getRemoteEvents={fetchEvents}
        recourseHeaderComponent={recourseHeaderComponent}
        stickyNavitation={true}
        eventRenderer={eventRenderer}
      />
    </div>
  );
}

export default UserView;
