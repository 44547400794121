const setCookie = (name, value, days) => {
  let expires = "";
  if (days) {
    let date = new Date();
    date.setTime(date.getTime() + days * 24 * 60 * 60 * 1000);
    expires = "; expires=" + date.toUTCString();
  }
  document.cookie = name + "=" + encodeURIComponent(value) + expires + "; path=/";
};

const getCookie = (name) => {
  let nameEQ = name + "=";
  let cookies = document.cookie.split("; ");
  for (let cookie of cookies) {
    if (cookie.indexOf(nameEQ) === 0) {
      return decodeURIComponent(cookie.substring(nameEQ.length));
    }
  }
  return null;
};

const getHashVariable = (key) => {
  const hash = window.location.hash.substring(1); // Remove the '#' character
  const params = new URLSearchParams(hash);
  return params.get(key);
};

const moveItemInArray = (arr, index, direction) => {
  if (index === -1) {
    console.log(`Item with index ${index} not found in the array.`);
    return arr;
  }

  if (direction === "up" && index > 0) {
    // Swap the item with the one above it
    [arr[index - 1], arr[index]] = [arr[index], arr[index - 1]];
  } else if (direction === "down" && index < arr.length - 1) {
    // Swap the item with the one below it
    [arr[index], arr[index + 1]] = [arr[index + 1], arr[index]];
  }

  return JSON.parse(JSON.stringify(arr)).map((item, index) => ({ ...item, order: index }));
};

const modifyOrPrependItem = (arr, newItemProps) => {
  const { id, ...otherProps } = newItemProps;

  if (id) {
    // If an ID is provided, find and modify the item
    const index = arr.findIndex((item) => item.id === id);
    if (index !== -1) {
      arr[index] = { ...arr[index], ...otherProps };
    } else {
      console.log(`Item with ID ${id} not found in the array. Cannot modify.`);
    }
  } else {
    // If no ID is provided, prepend a new item to the array
    const newItem = { ...newItemProps, id: null };
    arr = arr.map((item) => ({ ...item, order: item.order + 1 }));
    arr.unshift(newItem);
  }

  return arr;
};

function deleteItem(arr, itemToDelete) {
  const index = arr.findIndex((item) => item.id === itemToDelete.id);

  if (index !== -1) {
    arr.splice(index, 1); // Remove the item at the found index
  } else {
    console.log(`Item with ID ${itemToDelete.id} not found in the array. Cannot delete.`);
  }
  arr.sort((a, b) => a.order - b.order);
  return arr.map((item, index) => ({ ...item, order: index }));
}

export { getHashVariable, moveItemInArray, modifyOrPrependItem, deleteItem, setCookie, getCookie };
