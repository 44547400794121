import Tab from "@mui/material/Tab";

const resources = [
  {
    court_id: 1,
    title: "Грунт 1",
    color: "#f8a943",
  },
  {
    court_id: 2,
    title: "Грунт 2",
    color: "#f8a943",
  },
  {
    court_id: 3,
    title: "Зал",
    color: "#0167d3",
  },
];

const resourceFields = {
  idField: "court_id",
  textField: "title",
  subTextField: "",
  avatarField: "",
  colorField: "color",
};

const week = {
  weekDays: [0, 1, 2, 3, 4, 5, 6],
  weekStartOn: 1,
  startHour: 7,
  endHour: 21,
  step: 60,
  navigation: true,
  disableGoToDay: false,
};

const userDay = {
  startHour: 7,
  endHour: 21,
  step: 60,
  navigation: true,
};

const adminDay = {
  startHour: 7,
  endHour: 21,
  step: 60,
  navigation: true,
};

const month = {
  weekDays: [0, 1, 2, 3, 4, 5, 6],
  weekStartOn: 1,
  startHour: 7,
  endHour: 21,
  navigation: true,
  disableGoToDay: false,
};

const fields = [
  {
    name: "court_id",
    type: "select",
    default: resources[0].court_id,
    options: resources.map((res) => {
      return {
        id: res.court_id,
        text: `${res.title}`,
        value: res.court_id,
      };
    }),
    config: { label: "Корт", required: true },
  },
  {
    name: "repeat",
    type: "select",
    default: 1,
    options: [
      {
        id: 1,
        text: "Одноразово",
        value: 1,
      },
      ...[2, 3, 4, 8, 12, 16, 20, 24].map((num) => ({
        id: num,
        text: num < 4 ? `Протягом ${num} тижнів` : `Протягом ${num / 4} ${num / 4 === 1 ? "місяця" : "місяців"}`,
        value: num,
      })),
    ],
    config: { label: "Повторюваність", required: true },
  },
  // {
  //   name: "payment_successfull",
  //   type: "select",
  //   default: "true",
  //   options: [
  //     {
  //       id: 1,
  //       text: `Не сплачено`,
  //       value: "false",
  //     },
  //     {
  //       id: 2,
  //       text: `Сплачено`,
  //       value: "true",
  //     },
  //   ],
  //   config: { label: "Статус оплати", required: true },
  // },
];

const userFields = [
  {
    name: "court_id",
    type: "select",
    default: resources[0].court_id,
    options: resources.map((res) => {
      return {
        id: res.court_id,
        text: `${res.title}`,
        value: res.court_id,
      };
    }),
    config: { label: "Корт", required: true },
  },
];

const translations = {
  navigation: {
    month: "Місяць",
    week: "Тиждень",
    day: "День",
    today: "Сьогодні",
  },
  form: {
    addTitle: "Додати",
    editTitle: "Редагувати",
    confirm: "Підтвердити",
    delete: "Видалити",
    cancel: "Відміна",
  },
  event: {
    title: "Заголовок",
    start: "Початок",
    end: "Кінець",
    allDay: "Цілий День",
  },
  moreEvents: "Більше...",
  loading: "Завантаження...",
};

const view = window.innerWidth > 768 ? "week" : "day";

const recourseHeaderComponent = (resource) => {
  const divStyle = {
    background: "#f8a943",
  };
  const tabStyle = {
    backgroundColor: resource.color,
    color: "#fff",
    display: "block",
    width: "100%",
    fontWeight: 700,
    border: "2px solid #e0e0e0",
    lineHeight: 2,
    opacity: 1,
  };
  if (resource.court_id === 1) {
    divStyle.paddingLeft = "50px";
  }
  return (
    <div style={divStyle}>
      <Tab style={tabStyle} label={resource.title} />
    </div>
  );
};

export {
  resources,
  resourceFields,
  week,
  userDay,
  adminDay,
  month,
  fields,
  userFields,
  translations,
  view,
  recourseHeaderComponent,
};
